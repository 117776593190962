body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
}

/* Specific classes */

.w-1\/4 {
    width: 25%;
}

.h-2\/3 {
    height: 66.67%;
}

.rounded-md {
    border-radius: 0.375rem
        /* 6px */
    ;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.flex {
    display: flex;
}

.justify-end {
    justify-content: flex-end;
}

.h-0 {
    height: 0;
}

.flex-col {
    flex-direction: column;
}

.min-h-full {
    min-height: 100%;
}

.justify-center {
    justify-content: center;
}

.my-2 {
    margin-top: 0.5rem
        /* 8px */
    ;
    margin-bottom: 0.5rem
        /* 8px */
    ;
}

.font-bold {
    font-weight: 700;
}

.text-xl {
    font-size: 1.25rem
        /* 20px */
    ;
    line-height: 1.75rem
        /* 28px */
    ;
}

.text-xs {
    font-size: 0.75rem
        /* 12px */
    ;
    line-height: 1rem
        /* 16px */
    ;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}

.relative {
    position: relative;
}

.h-12 {
    height: 3rem;
}

.w-2\/3 {
    width: 66.666667%;
}

.min-w-\[200px\] {
    min-width: 200px;
}

/* Input styles */



.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

.rounded-sm {
    border-radius: 0.125rem
        /* 2px */
    ;
}

.\!border {
    border-width: 1px !important;
}

.\!border-gray-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.border-t-transparent {
    border-top-color: transparent;
}

.bg-transparent {
    background-color: transparent;
}



.py-2\.5 {
    padding-top: 0.625rem
        /* 10px */
    ;
    padding-bottom: 0.625rem
        /* 10px */
    ;
}

.font-sans {
    font-family: Roboto;
}

.text-sm {
    font-size: 0.875rem
        /* 14px */
    ;
    line-height: 1.25rem
        /* 20px */
    ;
}

.font-normal {
    font-weight: 400;
}

.shadow-lg {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.shadow-gray-900\/5 {
    box-shadow: 0 0 10px 0 rgba(27, 31, 35, 0.16);
}

.outline {
    outline: 2px solid transparent;
}

.outline-0 {
    outline: 0;
}

.ring-4 {
    border-radius: 4px;
    box-shadow: 0 0 0 4px rgba(156, 163, 175, 0.5);
}

.ring-transparent {
    border-color: transparent;
}

.transition-all {
    transition: all ease-in-out 0.2s;
}

.placeholder {
    /* Additional styles for input placeholder text can be added here */
}

.text-gray-500 {
    color: #a0aec0;
}

.placeholder-shown {
    border-color: #f2f2f7;
    /* Applies to input borders when placeholder is visible */
    border-top-color: #f2f2f7;
    /* Applies to input top border when placeholder is visible */
}

.focus\:border-2:focus {
    border-width: 2px;
}

.simpleTextClass{
    color: var(--grey-600, #757575);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.15px;
}
.minimalTextClass{
    color: var(--grey-600, #757575);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%; /* 19.92px */
    letter-spacing: 0.4px;
}
.headingClass{
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.4%; /* 32.016px */
}
.px-3 {
    padding-left: 0.75rem
        /* 12px */
    ;
    padding-right: 0.75rem
        /* 12px */
    ;
}
@media (min-width:1400px){
    .paddingFarePrice {
        padding-left: 1rem
            /* 12px */
        ;
        padding-right: 1rem
            /* 12px */
        ;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .specialFW {
        width: 133px !important;
    }
}